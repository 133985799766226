import * as React from "react"
import Image from "next/image"

type Props = {
    isClosed?: boolean
    onToggle: (sidebarShouldHide: boolean) => void
}

const SidebarToggle: React.FunctionComponent<Props> = ({ isClosed, onToggle, children }) => {
    const [sidebarHidden, setSidebarHidden] = React.useState<boolean>(isClosed === true)

    // Sync isClosed with sidebarHidden state
    React.useEffect(() => {
        if (isClosed === true && sidebarHidden !== isClosed) {
            setSidebarHidden(isClosed === true)
        }
    }, [isClosed, sidebarHidden])

    return (
        <div className="my-auto">
            <button onClick={() => {
                const nextSidebarHidden = !sidebarHidden
                setSidebarHidden(nextSidebarHidden)
                onToggle && onToggle(nextSidebarHidden)
            }}>
                {
                    sidebarHidden
                        ? <Image width="32" height="32" src="/images/ic_nav_side_menu.svg" alt="Open Menu" />
                        : <Image width="32" height="32" src="/images/ic_nav_close.svg" alt="Close Menu" />
                }
            </button>
        </div>
    )
}

export default SidebarToggle
