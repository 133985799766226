import * as React from "react"
import { useRouter } from "next/router"
import Image from "next/image"
import Link from "next/link"

import { OMQuery, OMReference, OMUniverse } from "@apptivity-lab/firmament-node-sdk"
import { useProfile } from "@lib"

import { AffAttendeeProfile, AffRenewalOrder } from "@firmament-packages/aff"
import { CnxMeeting } from "@firmament-packages/connectx"
import { PmtStripePayment } from "@firmament-packages/payments_stripe"
import { RenewalOrder, Subscription } from "@firmament-packages/paid_memberships"

import "../../firmament-packages/aff/extensions/AffAttendeeProfileExtensions"

import SiteHeader from "@components/SiteHeader"
import SidebarLayout, { SidebarLayoutContext } from "@layouts/SidebarLayout"
import AFFSidebarHeader from "./AFFSidebarHeader"
import AFFSiteFooter from "./AFFSiteFooter"
import CnxMeetingUpcomingView from "./CnxMeeting/CnxMeetingUpcomingView"
import GoogleAnalytics from "@components/GoogleAnalytics"

const AFFAppBarBrand: React.FunctionComponent<{}> = () => {
    const attendeeProfile = useProfile(AffAttendeeProfile)

    if (OMUniverse.shared.currentUser?.isAuthenticated === true) {
        return (
            <div className="relative flex">
                <div className="relative flex h-[40px] max-h-[40px]">
                    <div className="relative w-[40px] h-[40px]">
                        <Image width="40" height="40" objectFit="cover"
                            src="/images/ic_brand_logo_color.svg"
                            alt="Logo (Icon)" />
                    </div>
                    <div className="absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] z-10">
                        <div className="relative w-[24px] h-[24px] bg-white-900 rounded-full overflow-hidden">
                            <Image width="24" height="24" objectFit="cover"
                                src={attendeeProfile?.photo?.actualObject?.url || "/images/ic_avatar.svg"}
                                alt="Profile Photo" />
                        </div>
                    </div>
                </div>
                <div className="ml-[12px] hidden sm:block">
                    <Image width="80" height="40" src="/images/ic_brand_name_black.svg" alt="..." />
                </div>
            </div>
        )
    } else {
        return <Image width="130" height="40" src="/images/ic_brand_wordmark_color.svg" alt="Logo (Color)" />
    }
}

type LinkProps = {
    href: string
}
const SidebarLinkItem: React.FunctionComponent<LinkProps> = ({ href, children }) => {
    const router = useRouter()

    const className = React.useMemo(() => {
        return [
            "block px-[16px] py-[12px] hover:bg-primary-100 rounded",
            "text-primary-900",
            router.asPath === href ? "bg-primary-100" : undefined
        ].join(" ")
    }, [href, router])

    return (
        <Link href={href}><a className={className}>{children}</a></Link>
    )
}

const AuthenticatedSidebarContent: React.FunctionComponent<{
    onLogout: () => void
}> = ({ onLogout }) => {
    const { sidebarHidden } = React.useContext(SidebarLayoutContext)

    const attendeeProfile = useProfile(AffAttendeeProfile)

    const [currentSubscription, setCurrentSubscription] = React.useState<Subscription | null>()
    const latestPaymentRef = React.useRef<PmtStripePayment | null>()

    React.useEffect(() => {
        if (attendeeProfile && !sidebarHidden) {
            attendeeProfile.loadActiveSubscriptions()
                .then((subscription) => {
                    if (subscription) {
                        const orders = subscription.renewalOrders?.reduce((result: AffRenewalOrder[], current: OMReference<RenewalOrder>) => {
                            const renewalOrder = new OMReference(AffRenewalOrder, current.id).actualObject
                            if (renewalOrder) {
                                result.push(renewalOrder)
                            }
                            return result
                        }, [])
                        if (orders && orders.length > 0) {
                            const latestPayment = orders[0].latestPayment()
                            latestPaymentRef.current = latestPayment
                        }
                    }

                    setCurrentSubscription(subscription)
                })
        }
    }, [attendeeProfile, sidebarHidden])

    return (
        <>
            <AFFSidebarHeader
                attendeeProfile={attendeeProfile}
                currentSubscription={currentSubscription || undefined}
                latestPayment={latestPaymentRef.current || undefined}
            />

            <div className="px-[20px] py-[16px]">
                <ul className="space-y-[8px] text-headline uppercase">
                    <li><SidebarLinkItem href="/classes">Live Schedule</SidebarLinkItem></li>
                    <li>
                        <SidebarLinkItem href={currentSubscription ? "/on-demand" : "/profile/membership"}>
                            On-Demand Classes
                            {!currentSubscription && <i className="ml-[12px] fa-solid fa-lock"></i>}
                        </SidebarLinkItem>
                    </li>
                    <li><SidebarLinkItem href="/profile">My Profile</SidebarLinkItem></li>
                    <li><a target="_blank" href="https://alivefitandfree.com/blog/" rel="noopener noreferrer" className="block px-[16px] py-[12px] hover:bg-primary-100 text-primary-900 rounded">Blog</a></li>
                    <li><a target="_blank" href="https://alivefitandfree.com/faqs/" rel="noopener noreferrer" className="block px-[16px] py-[12px] hover:bg-primary-100 text-primary-900 rounded">{"FAQ's"}</a></li>
                    <li><a target="_blank" href="https://www.facebook.com/groups/669081934539651/" rel="noopener noreferrer" className="block px-[16px] py-[12px] hover:bg-primary-100 text-primary-900 rounded">Facebook Group</a></li>
                </ul>

                <hr className="border-black-200 mt-4" />

                <div className="mt-[16px] px-[16px] text-footnote text-black-600">Have Questions?</div>
                <ul className="mt-[8px] space-y-[8px] text-headline">
                    <li><a target="_blank" href="https://alivefitandfree.com/contact-us/" className="block px-[16px] py-[12px] hover:bg-primary-100 text-primary-900 uppercase rounded" rel="noopener noreferrer">Chat With Us</a></li>
                    <li><span className="block px-[16px] py-[12px] hover:bg-primary-100 text-primary-900 uppercase rounded">480-458-7497</span></li>
                    <li><a href="mailto:hello@alivefitandfree.com" className="block px-[16px] py-[12px] hover:bg-primary-100 text-primary-900 rounded">hello@alivefitandfree.com</a></li>
                </ul>

                <hr className="border-black-200 mt-4" />

                <button className="text-left w-full mt-[8px] px-[16px] py-[12px] text-headline text-black-600 hover:bg-primary-100 uppercase rounded"
                    onClick={() => onLogout()}>
                    Log Out
                </button>
            </div>
        </>
    )
}

const VisitorSidebarContent: React.FunctionComponent<{}> = () => (
    <>
        <AFFSidebarHeader />
        <div className="px-[20px] py-[16px]">
            <ul className="mt-[8px] space-y-[8px] text-headline uppercase">
                <li><SidebarLinkItem href="https://alivefitandfree.com">Home</SidebarLinkItem></li>
                <li><SidebarLinkItem href="/classes">Live Schedule</SidebarLinkItem></li>
                {/* <li><SidebarLinkItem href="https://alivefitandfree.com/">About Us</SidebarLinkItem></li> */}
                <li><SidebarLinkItem href="https://alivefitandfree.com/pricing-faqs/">{"Pricing & FAQ's"}</SidebarLinkItem></li>
                <li><SidebarLinkItem href="https://alivefitandfree.com/blog/">Blog</SidebarLinkItem></li>
            </ul>

            {/* <hr className="border-black-200 mt-4" />

            <div className="mt-[16px] px-[16px] text-footnote text-black-600">Are you a senior living community?</div>
            <ul className="mt-[8px] space-y-[8px] text-headline uppercase">
                <li><SidebarLinkItem href="https://alivefitandfree.com/communities/">For Communities</SidebarLinkItem></li>
            </ul> */}

            <hr className="border-black-200 mt-4" />

            <div className="mt-[16px] px-[16px] text-footnote text-black-600">Have Questions?</div>
            <ul className="mt-[8px] space-y-[8px] text-headline">
                <li><a href="https://alivefitandfree.com/contact-us/" className="block px-[16px] py-[12px] hover:bg-primary-100 text-primary-900 uppercase rounded">Chat With Us</a></li>
                <li><span className="block px-[16px] py-[12px] hover:bg-primary-100 text-primary-900 uppercase rounded">480-458-7497</span></li>
                <li><a href="mailto:hello@alivefitandfree.com" className="block px-[16px] py-[12px] hover:bg-primary-100 text-primary-900 rounded">hello@alivefitandfree.com</a></li>
            </ul>
        </div>
    </>
)

const loadUpcomingMeetings = async (attendeeProfile: AffAttendeeProfile) => {
    const from = new Date(Date.now() - (15 * 60 * 60 * 1000))

    const query = new OMQuery(CnxMeeting, "meetings", new OMReference(AffAttendeeProfile, attendeeProfile))
        .filter("scheduledStartAt", "moreThanEqual", from.toISOString() as any)
        .filter("scheduledEndAt", "moreThanEqual", new Date().toISOString() as any)
        .filter("meetingStatus", "notEquals", "FAILED")
        .filter("meetingStatus", "notEquals", "COMPLETED")
        .sort("scheduledStartAt", "ASC")
        .limit(10)

    await query.execute()

    return query.resultObjects
}

const AFFSidebarLayout: React.FunctionComponent<{
    onLogout?: () => void
}> = ({ children, onLogout }) => {
    const attendeeProfile = OMUniverse.shared.currentUser?.user?.attendeeProfile()

    const [upcomingMeetings, setUpcomingMeetings] = React.useState<CnxMeeting[]>()

    React.useEffect(() => {
        if (!attendeeProfile && upcomingMeetings !== undefined) {
            setUpcomingMeetings(undefined)
        }
        if (!attendeeProfile || upcomingMeetings !== undefined) {
            return
        }

        loadUpcomingMeetings(attendeeProfile)
            .then((meetings) => setUpcomingMeetings(meetings))
    }, [attendeeProfile, upcomingMeetings])

    const router = useRouter()

    return (
        <>
            <SiteHeader />
            <GoogleAnalytics />
            <SidebarLayout
                appBarBrand={<AFFAppBarBrand />}
                appBarRightNavigation={
                    OMUniverse.shared.currentUser?.isAuthenticated === true
                        ? <>{upcomingMeetings && upcomingMeetings.length > 0 && <CnxMeetingUpcomingView meeting={upcomingMeetings[0]} />}</>
                        : (
                            <button type="button" className="button-primary"
                                onClick={() => {
                                    router.push(`/auth/register?return=${router.asPath}`)
                                }}>
                                Get Started
                            </button>
                        )
                }
                sidebarContent={
                    OMUniverse.shared.currentUser?.isAuthenticated === true
                        ? <AuthenticatedSidebarContent onLogout={() => onLogout && onLogout()} />
                        : <VisitorSidebarContent />
                }>

                <div className="bg-primary-100 w-full h-full">
                    <div className="bg-white-900 min-h-[72vh]">
                        {children}
                    </div>

                    <AFFSiteFooter />
                </div>
            </SidebarLayout>
        </>
    )
}

export default AFFSidebarLayout
