import { OMQuery, OMReference } from "@apptivity-lab/firmament-node-sdk"
import { AffExpertProfile, AffAttendeeProfile } from "@firmament-packages/aff"
import { CnxConsumerProfile, CnxMeeting } from ".."

declare module ".." {
    interface CnxMeeting {
        localYearMonth(timeZone?: string): string | undefined
        scheduledDurationSeconds(): number
        shortScheduledDateDisplay(timeZone?: string): string
        longScheduledDateDisplay(timeZone?: string): string
        joinAtTimeDisplay(timeZone?: string): string
        servicePriceCents(): number
        isLiveNow(): boolean
        hasEnded(): boolean
        overallClassRatingAverage(): number
        overallClassRatingCount(): number
        shouldPromptLeaveRating(attendeeProfile: AffAttendeeProfile): Promise<CnxMeeting | null>
    }
}

CnxMeeting.prototype.localYearMonth = function(timeZone?: string) {
    if (!this.scheduledStartAt) {
        return undefined
    }

    const year = this.scheduledStartAt.toLocaleDateString("en-US", {
        "year": "numeric",
        timeZone
    })
    const month = this.scheduledStartAt.toLocaleDateString("en-US", {
        "month": "2-digit",
        timeZone
    })

    return `${year}${month}`
}

CnxMeeting.prototype.scheduledDurationSeconds = function() {
    if (!this.scheduledStartAt || !this.scheduledEndAt) {
        return 0
    }

    return (this.scheduledEndAt.getTime() - this.scheduledStartAt.getTime()) / 1000
}

CnxMeeting.prototype.shortScheduledDateDisplay = function(timeZone?: string) {
    if (!this.scheduledStartAt || !this.scheduledEndAt) {
        return "-"
    }

    const dateString = this.scheduledStartAt.toLocaleDateString(
        "en-US",
        {
            month: "short",
            day: "numeric",
            timeZone
        }
    )

    const fromTimeString = this.scheduledStartAt.toLocaleTimeString(
        "en-US",
        { hour: "numeric", hour12: true, minute: "2-digit", timeZone }
    )

    return `${dateString}, ${fromTimeString}`
}

CnxMeeting.prototype.longScheduledDateDisplay = function(timeZone?: string) {
    if (!this.scheduledStartAt || !this.scheduledEndAt) {
        return "-"
    }

    const dateString = this.scheduledStartAt.toLocaleDateString(
        "en-US",
        {
            month: "short",
            day: "numeric",
            timeZone
        }
    )

    const fromTimeString = this.scheduledStartAt.toLocaleTimeString(
        "en-US",
        { hour: "numeric", hour12: true, minute: "2-digit", timeZone }
    )
    const toTimeString = this.scheduledEndAt.toLocaleTimeString(
        "en-US",
        { hour: "numeric", hour12: true, minute: "2-digit", timeZone }
    )

    return `${dateString}, ${fromTimeString} - ${toTimeString}`
}

CnxMeeting.prototype.joinAtTimeDisplay = function(timeZone?: string) {
    if (!this.scheduledStartAt || !this.scheduledEndAt) {
        return "-"
    }

    const dateString = this.scheduledStartAt.toLocaleDateString(
        "en-US",
        {
            month: "short",
            day: "numeric",
            timeZone
        }
    )

    const preserviceMs = (this.serviceTier.actualObject?.preserviceMins || 0) * 60 * 1000
    const joinAt = new Date(this.scheduledStartAt.getTime() - preserviceMs)

    const fromTimeString = joinAt.toLocaleTimeString(
        "en-US",
        { hour: "numeric", hour12: true, minute: "2-digit", timeZone }
    )

    return `${dateString}, ${fromTimeString}`
}

CnxMeeting.prototype.servicePriceCents = function() {
    return this.serviceTier.actualObject!.serviceGrossCents
}

CnxMeeting.prototype.isLiveNow = function() {
    const now = new Date()
    const preserviceMs = (this.serviceTier.actualObject?.preserviceMins || 0) * 60 * 1000

    if (this.actualEndAt && this.actualEndAt.getTime() < now.getTime()) {
        // Meeting has ended
        return false
    }
    if (!this.actualEndAt && this.scheduledEndAt && this.scheduledEndAt.getTime() < now.getTime()) {
        // Meeting should have ended ended
        return false
    }

    if (this.actualStartAt) {
        return this.actualStartAt.getTime() <= now.getTime() &&
            (!this.actualEndAt || this.actualEndAt.getTime() > now.getTime())
    }
    if (this.scheduledStartAt) {
        const hasStarted = this.scheduledStartAt.getTime() - preserviceMs < now.getTime()
        const hasEnded = this.scheduledEndAt && this.scheduledEndAt.getTime() < now.getTime()

        return hasStarted && !hasEnded
    }

    return false
}

CnxMeeting.prototype.hasEnded = function() {
    if (this.meetingStatus === "COMPLETED") {
        return true
    }

    if (!this.scheduledEndAt) {
        return false
    }

    const now = new Date()
    return now.getTime() > this.scheduledEndAt.getTime()
}

CnxMeeting.prototype.overallClassRatingCount = function() {
    if (this.meetingTemplate) {
        // Recurring meeting, therefore use meeting template overall
        return this.meetingTemplate?.actualObject?.overallRatingsCount || 0
    } else if (this.provider) {
        // Non-recurring meeting, use expert profile ratings
        const expertProfile = new OMReference(AffExpertProfile, this.provider.id).actualObject
        return expertProfile?.overallRatingsCount || 0
    }

    return 0
}

CnxMeeting.prototype.overallClassRatingAverage = function() {
    if (this.meetingTemplate) {
        // Recurring meeting, therefore use meeting template overall
        return parseFloat(`${this.meetingTemplate?.actualObject?.overallRatingsAverage || 0}`)
    } else if (this.provider) {
        // Non-recurring meeting, use expert profile ratings
        const expertProfile = new OMReference(AffExpertProfile, this.provider.id).actualObject
        return parseFloat(`${expertProfile?.overallRatingsAverage || 0}`)
    }

    return 0
}

CnxMeeting.prototype.shouldPromptLeaveRating = async function (attendeeProfile: AffAttendeeProfile) {
    if (this.meetingTemplate) {
        // Recurring meeting
        const pastAttendedMeetings = await new OMQuery(CnxMeeting, "attendedMeetings", new OMReference(CnxConsumerProfile, attendeeProfile))
            .filter("meetingTemplate", "equals", this.meetingTemplate.id as any)
            .filter("scheduledStartAt", "lessThanEqual", new Date().toISOString() as any)
            .filterGroup([
                ["actualStartAt", "lessThanEqual", new Date().toISOString() as any],
                ["scheduledStartAt", "lessThanEqual", new Date().toISOString() as any],
            ], "OR")
            .sort("scheduledEndAt", "DESC")
            .limit(5)
            .execute()
        if (pastAttendedMeetings.resultObjects.length > 0) {
            return pastAttendedMeetings.resultObjects[0]
        } else {
            return null
        }
    } else {
        // Non-recurring meeting
        if (this.actualEndAt && this.actualEndAt.getTime() <= Date.now()) {
            return this
        } else if (this.scheduledEndAt && this.scheduledEndAt.getTime() <= Date.now()) {
            return this
        }
    }

    return null
}
