import React from "react"

const ErrorTag: React.FunctionComponent<{ className?: string }> = ({
    className
}) => {
    return (
        <span className={[className ? className : ""].concat(["px-[4px] py-[2px] text-caption1 text-white-900 bg-red-700 rounded-[4px] uppercase"]).join(" ")}>
            Error
        </span>
    )
}

export default ErrorTag
