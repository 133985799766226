import * as React from "react"

type Props = {
    hidden?: boolean
}

const Sidebar: React.FunctionComponent<Props> = ({ hidden, children }) => {
    const classNames = React.useMemo(() => {
        return [
            "fixed w-full sm:w-auto sm:min-w-[414px] sm:max-w-[414px]",
            "top-[60px] sm:top-[80px] bottom-0 z-30",
            "pb-[20px]",
            "bg-white-900 border-right-2 border-r border-gray-200",
            "overflow-auto overscroll-contain",
            "filter shadow-md",
            "transform transition-transform duration-500 ease-in-out",
            hidden ? "-translate-x-full" : "translate-x-0"
        ].join(" ")
    }, [hidden])

    return (
        <div id="sidebar" className={classNames}>
            {children}
        </div>
    )
}

export default Sidebar
