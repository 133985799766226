import { useUpdateableState } from "@lib"
import { useCallback, useState } from "react"

export type InputValidator<T> = (input?: T) => Error | undefined

export const emailValidator: InputValidator<string> = (input?: string) => {
    if (!input) {
        return undefined
    }

    const emailRegExp = /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/gi
    if (!emailRegExp.test(input)) {
        return new Error("Please enter a valid email address (example: someone@example.com)")
    }

    return undefined
}
export const isEmail = (input?: string) => {
    return emailValidator(input) === undefined
}

export interface TextInputProps {
    type: "text" | "number" | "textarea" | "email" | "password"
    className?: string
    title?: string
    placeholder?: string
    helperText?: string
    isRequired?: boolean,
    value?: string
    error?: Error
    validator?: InputValidator<string>
    onChange?: (text: string) => void
}

const TextInput: React.FunctionComponent<TextInputProps> = ({
    type,
    className,
    title,
    placeholder,
    helperText,
    isRequired,
    value,
    error,
    validator,
    onChange,
}: TextInputProps) => {
    const [input, setInput] = useUpdateableState(value || "")
    const [inputError, setInputError] = useUpdateableState<Error>(error)

    const onInputChange = useCallback((event) => {
        if (input === event.target.value) {
            return
        }

        onChange && onChange(event.target.value || "")

        setInput(event.target.value || "")
        setInputError(undefined)
    }, [input, onChange, setInput, setInputError])

    const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false)
    const togglePasswordVisibility = useCallback((event) => {
        const nextPasswordVisible = !isPasswordVisible
        setPasswordVisible(nextPasswordVisible)
    }, [isPasswordVisible])

    return (
        <div className={className}>
            {title &&
                <label className="block text-footnote text-black-700 text-left mb-[8px]">
                    {title}
                </label>
            }

            {
                type === "textarea"
                    ? <textarea className="w-full py-1 px-2 rounded font-mono focus:outline-primary-900" rows={5}
                        value={input}
                        onBlur={(event) => setInputError(validator && validator(event.target.value || ""))}
                        onChange={onInputChange}
                        />
                    : (
                        <div className="group flex py-[12px] px-[16px] bg-white-900 border-[1px] border-black-500 hover:border-primary-900 rounded-[4px]">
                            <input type={type === "password" ? (isPasswordVisible ? "text" : "password") : type}
                                className="w-full text-body focus:outline-0 bg-transparent"
                                placeholder={placeholder}
                                value={input}
                                onBlur={(event) => setInputError(validator && validator(event.target.value || ""))}
                                onChange={onInputChange}
                                />

                            {type === "password" && <button type="button" onClick={(event) => togglePasswordVisibility(event)}>
                                {isPasswordVisible
                                    ? <i className="text-black-900 fa-solid fa-eye"></i>
                                    : <i className="text-black-900 fa-solid fa-eye-slash"></i>
                                }
                            </button>}
                        </div>
                    )
            }

            {helperText && !inputError && <div className="mt-[8px] text-footnote text-left text-black-500">{helperText}</div>}
            {inputError && <div className="text-alert-900 text-left"><b>{inputError.message}</b></div>}
        </div>
    )
}

export default TextInput
