import { OMReference } from "@apptivity-lab/firmament-node-sdk"
import { AffRenewalOrder } from "@firmament-packages/aff"
import { PmtStripePayment } from "@firmament-packages/payments_stripe"

declare module "@firmament-packages/aff" {
    interface AffRenewalOrder {
        createdAtDisplay(): string
        paymentStatus(): "pending" | "successful" | "failed"
        formattedAmount(): string
        latestPayment(): PmtStripePayment | null
    }
}

AffRenewalOrder.prototype.createdAtDisplay = function() {
    const dateComponents = [
        this.createdAt.toLocaleDateString("en-US", { day: "numeric" }),
        this.createdAt.toLocaleDateString("en-US", { month: "short" }),
        this.createdAt.toLocaleDateString("en-US", { year: "numeric" })
    ]
    return dateComponents.join(" ")
}

AffRenewalOrder.prototype.paymentStatus = function() {
    if (this.status === "COMPLETED") {
        return "successful"
    }

    for (const payment of (this.payments || [])) {
        if (payment.actualObject?.status === "CHARGED") {
            return "successful"
        }
    }

    if (this.status === "CHECKED_OUT") {
        return "pending"
    }

    return "failed"
}

AffRenewalOrder.prototype.formattedAmount = function() {
    if (this.status === "COMPLETED" && this.paymentsCount === 0) {
        return "-"
    } else {
        return `$${((this.totalPriceCents || 0) / 100.0).toFixed(2)}`
    }
}

AffRenewalOrder.prototype.latestPayment = function() {
    const payments = (this.payments || [])
        .map((each) => each.actualObject)
        .filter((each) => each)
        .sort((a, b) => (b?.modifiedAt.getTime() || 0) - (a?.modifiedAt.getTime() || 0))

    let latestPayment: PmtStripePayment | null = null
    for (const payment of payments) {
        if (!payment) {
            continue
        }
        const stripePayment = new OMReference(PmtStripePayment, payment.id).actualObject
        if (stripePayment) {
            latestPayment = stripePayment
            break
        }
    }

    return latestPayment
}
