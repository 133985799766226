import * as React from "react"
import { useRouter } from "next/router"

import AppBar from "./AppBar"
import Sidebar from "./Sidebar"
import SidebarToggle from "./SidebarToggle"

type Props = {
    appBarBrand?: React.ReactNode,
    appBarRightNavigation?: React.ReactNode,
    sidebarContent?: React.ReactNode
}

export const SidebarLayoutContext = React.createContext<{
    sidebarHidden: boolean
}>({
    sidebarHidden: true
})

const SidebarLayout: React.FunctionComponent<Props> = ({ appBarBrand, appBarRightNavigation, sidebarContent, children }) => {
    const router = useRouter()
    const [sidebarHidden, setSidebarHidden] = React.useState<boolean>(true)

    React.useEffect(() => {
        router.events.on("routeChangeComplete", () => {
            if (!sidebarHidden) {
                setSidebarHidden(true)
            }
        })
    }, [router.events, sidebarHidden])

    return (
        <SidebarLayoutContext.Provider value={{
            sidebarHidden
        }}>
            <div>
                <AppBar
                    brand={appBarBrand}
                    leftNavigationItem={<SidebarToggle isClosed={sidebarHidden} onToggle={(sidebarShouldHide) => setSidebarHidden(sidebarShouldHide)} />}
                    rightNavigationItem={appBarRightNavigation}
                    />

                <div>
                    <Sidebar hidden={sidebarHidden}>
                        {sidebarContent}
                    </Sidebar>
                </div>

                <div>{children}</div>
            </div>
        </SidebarLayoutContext.Provider>
    )
}

export default SidebarLayout
