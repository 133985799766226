import Image from "next/image"
import { useRouter } from "next/router"

import { OMUniverse } from "@apptivity-lab/firmament-node-sdk"
import { startOfWeek } from "@lib"

import { CnxMeeting } from "@firmament-packages/connectx"
import "../../../firmament-packages/connectx/extensions/CnxMeetingExtensions"

const CnxMeetingUpcomingView: React.FunctionComponent<{ meeting: CnxMeeting }> = ({ meeting }) => {
    const router = useRouter()
    const attendeeProfile = OMUniverse.shared.currentUser?.user.attendeeProfile()

    const navigateToBookedClasses = () => {
        if (!window) {
            return
        }

        const weekStartAt = startOfWeek(meeting.scheduledStartAt!).toISOString()

        if (window.location.pathname === "/classes") {
            // Force reload by using changing browser location if currently
            // user is at /classes page to temporarily address issue where
            // some UI components do not reflect selected query parameter.
            // TODO: Revisit this to see how we might be able to use router.push()
            // here as well.
            window.location.assign(`/classes?from=${weekStartAt}&segment=BOOKED`)
        } else {
            router.push(`/classes?from=${weekStartAt}&segment=BOOKED`)
        }
    }

    return (
        <div className="flex items-center gap-x-[16px] text-right cursor-pointer" onClick={() => navigateToBookedClasses()}>
            <div>
                {meeting.isLiveNow()
                    ? <div className="text-caption1 text-accent-900 uppercase">{"◉ Live Now"}</div>
                    : <div className="text-caption1 text-black-700">Next Class</div>
                }
                <div className="text-callout text-black-900">{meeting.shortScheduledDateDisplay(attendeeProfile?.timezone || undefined)}</div>
            </div>

            <Image width="24" height="24" src="/images/ic_nav_live_schedule.svg" alt="Next Class" />
        </div>
    )
}

export default CnxMeetingUpcomingView
