import * as React from "react"

type Props = {
    brand?: React.ReactNode
    leftNavigationItem?: React.ReactNode
    rightNavigationItem?: React.ReactNode
}

const AppBar: React.FunctionComponent<Props> = ({ brand, leftNavigationItem, rightNavigationItem, children }) => {
    return (
        <div className="flex justify-between sticky top-0 w-full z-40 px-[20px] py-[16px] bg-white-900 filter shadow-lg">
            <div className="flex my-auto">
                {leftNavigationItem}
                <div className="ml-[20px]">{brand}</div>
            </div>

            <div>{rightNavigationItem}</div>
        </div>
    )
}

export default AppBar
