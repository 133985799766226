import React from "react"
import Image from "next/image"
import Link from "next/link"

import { OMUniverse } from "@apptivity-lab/firmament-node-sdk"
import { fbTrackEvent } from "@lib"

import TextInput from "@components/forms/TextInput"

const AFFSiteFooter: React.FunctionComponent<{}> = () => (
    <footer className="p-[20px] sm:pt-[32px] sm:pb-[20px] text-white-900 bg-primary-900">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-[16px]">
            <div className="mb-[32px] sm:mb-0">
                <div className="mt-[12px] sm:hidden">
                    <Image width="200" height="62" src="/images/ic_brand_wordmark_white_big.svg" alt="Logo (White)" />
                </div>
                <div className="hidden sm:block relative w-[80%] h-[80%]">
                    <Image layout="fill" objectFit="cover" src="/images/ic_footer_logo_lg_md.svg" alt="Logo (White)" />
                </div>
            </div>

            <div>
                <ul className="text-subhead">
                    {OMUniverse.shared.currentUser?.isAuthenticated
                        ? <></>
                        : (
                            <li className="py-[12px] hover:bg-white-900 hover:text-primary-900">
                                <a href="https://alivefitandfree.com" target="_blank" rel="noopener noreferrer">
                                    About Us
                                </a>
                            </li>
                        )
                    }
                    <li className="py-[12px] hover:bg-white-900 hover:text-primary-900">
                        <a href={`https://alivefitandfree.com/${OMUniverse.shared.currentUser?.isAuthenticated ? "" : "pricing-"}faqs/`} target="_blank" rel="noopener noreferrer">
                            FAQ&lsquo;s
                        </a>
                    </li>
                    <li className="py-[12px] hover:bg-white-900 hover:text-primary-900">
                        <a href="https://alivefitandfree.com/terms-conditions" target="_blank" rel="noopener noreferrer">
                            {"Terms & Conditions"}
                        </a>
                    </li>
                    <li className="py-[12px] hover:bg-white-900 hover:text-primary-900">
                        <a href="https://alivefitandfree.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                        </a>
                    </li>
                </ul>
            </div>

            <div className="mt-[32px] sm:mt-[0px]">
                <h5 className="text-caption1 text-white-600 uppercase">Social Media</h5>
                <div className="mt-[8px] space-x-[20px] text-subhead">
                    <span>
                        <Link href="https://www.facebook.com/Alivefitandfree">
                            <a><Image width="32" height="32" src="/images/ic_footer_facebook_big.svg" alt="Facebook" /></a>
                        </Link>
                    </span>
                    <span>
                        <Link href="https://www.instagram.com/alivefitandfree/">
                            <a><Image width="32" height="32" src="/images/ic_footer_instagram_big.svg" alt="Instagram" /></a>
                        </Link>
                    </span>
                    <span>
                        <Link href="https://www.youtube.com/channel/UC_M-_ZY_RdacJwfX4aioDpw/">
                            <a><Image width="32" height="32" src="/images/ic_footer_youtube_big.svg" alt="YouTube" /></a>
                        </Link>
                    </span>
                    <span>
                        <Link href="https://www.tiktok.com/@alivefitandfree">
                            <a><Image width="32" height="32" src="/images/ic_footer_tiktok_big.svg" alt="TikTok" /></a>
                        </Link>
                    </span>
                    <span>
                        <Link href="https://www.pinterest.com/alivefitandfree/">
                            <a><Image width="32" height="32" src="/images/ic_footer_pinterest_big.svg" alt="Pinterest" /></a>
                        </Link>
                    </span>
                </div>
            </div>

            <div className="sm:col-span-2 sm:col-start-2 mt-[32px]">
                <h5 className="text-caption1 text-white-600 uppercase">Subscribe To Our Newsletter</h5>
                <form id="newsletter-form" className="newsletter-form" onSubmit={() => {
                    fbTrackEvent("SubmitApplication")
                }}>
                    <div className="block sm:flex mt-[8px]">
                        <TextInput type="email" className="w-full text-black-900" placeholder="Enter your email address" />
                        <button type="submit" className="w-full sm:w-auto mx-0 mt-[16px] sm:ml-[12px] sm:mt-0 py-[12px] px-[16px] border border-white-900 rounded-lg text-callout uppercase hover:bg-white-900 hover:text-primary-900">
                            Subscribe
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <hr className="border-white-200 mt-[32px]" />

        <div className="text-caption2 mt-[12px]">
            <p>
                &copy; 2022, Alive, Fit &amp; Free LLC. All rights reserved.
                <span className="ml-[8px] text-white-400 text-center text-caption3">v{process.env.APP_VERSION} [{process.env.UNIVERSE_ENVIRONMENT}]</span>
            </p>
        </div>
    </footer>
)

export default AFFSiteFooter
