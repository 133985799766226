import React from "react"
import Head from "next/head"

const SITE_NAME = "Alive, Fit & Free"
const SITE_DESCRIPTION = "Alive, Fit & Free is an easy way to get fitness in your home or anywhere. And it is a social outlet, especially when we've been sequestered during COVID-19."

const SiteHeader: React.FunctionComponent<{
    meta?: {
        title?: string
        description?: string,
        imageUrl?: string
    }
}> = ({ meta }) => {
    return (
        <Head>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />

            <title>{meta?.title ? `${SITE_NAME} | ${meta.title}` : SITE_NAME}</title>
            <meta property="og:title" content={meta?.title ? `${SITE_NAME} | ${meta.title}` : SITE_NAME} />

            <meta
                name="description"
                content={meta?.description || `${SITE_NAME} | ${SITE_DESCRIPTION}`}
            />
            <meta property="og:description" content={meta?.description || `${SITE_NAME} | ${SITE_DESCRIPTION}`} />

            {meta?.imageUrl && <meta property="og:image" content={meta.imageUrl} />}
        </Head>
    )
}

export default SiteHeader
