import React from "react"
import { useRouter } from "next/router"
import Image from "next/image"
import Link from "next/link"

import { OMUniverse } from "@apptivity-lab/firmament-node-sdk"

import { PmtStripePayment } from "@firmament-packages/payments_stripe"
import { Subscription } from "@firmament-packages/paid_memberships"
import { AffAttendeeProfile } from "@firmament-packages/aff"
import "../../firmament-packages/aff/extensions/AffRenewalOrderExtensions"

import FreeTrialTag from "@firmament-packages/aff/components/FreeTrialTag"
import ErrorTag from "@firmament-packages/aff/components/ErrorTag"

const AFFSidebarHeader: React.FunctionComponent<{
    attendeeProfile?: AffAttendeeProfile,
    currentSubscription?: Subscription,
    latestPayment?: PmtStripePayment
}> = ({ attendeeProfile, currentSubscription, latestPayment }) => {

    const router = useRouter()

    if (OMUniverse.shared.currentUser?.isAuthenticated === true) {
        return (
            <div className="relative bg-primary-900">
                <Image layout="fill" objectFit="cover" src="/images/bg_side_menu_header.svg" alt="Decoration" />

                <div className="relative flex px-[20px] py-[40px] gap-x-[20px] text-white-900">
                    <div className="relative w-[80px] h-[80px] overflow-hidden bg-white-600 rounded-full">
                        <Image width="80" height="80" objectFit="cover"
                            src={attendeeProfile?.photo?.actualObject?.url || "/images/ic_avatar.svg"}
                            alt="Profile Photo" />
                    </div>

                    <div className="max-w-[70%]">
                        <div className="text-title3">{attendeeProfile?.fullName || "Guest"}</div>
                        <div className="mt-[8px]">
                            {!currentSubscription && <div className="text-callout">AFF Free</div>}
                            {currentSubscription &&
                                <div className="text-callout flex items-center">
                                    {currentSubscription?.product?.actualObject?.title}
                                    {latestPayment?.failureReason
                                        ? <ErrorTag className="ml-[8px]" />
                                        : <>{currentSubscription.isTrial === true && <FreeTrialTag />}</>
                                    }
                                </div>
                            }

                            {!currentSubscription && <p className="text-subhead">Access free classes only</p>}
                            {currentSubscription && <p className="text-subhead">{currentSubscription?.product?.actualObject?.description}</p>}
                        </div>

                        {(!currentSubscription || currentSubscription.subscriptionProcessor !== "MANUAL") &&
                            <Link href="/profile/membership">
                                <a>
                                    <div className="mt-[8px] w-full button-primary text-center">
                                        Manage Membership
                                    </div>
                                </a>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="relative bg-primary-900">
                <Image layout="fill" objectFit="cover" src="/images/bg_side_menu_header.svg" alt="Decoration" />

                <div className="relative px-[20px] py-[40px] text-white-900">
                    <div><Image width="200" height="62" src="/images/ic_brand_wordmark_white_big.svg" alt="Logo (White)" /></div>

                    <div className="flex mt-[24px] gap-x-[20px]">
                        <Link href="/auth/login" passHref>
                            <button className="flex-1 px-[16px] py-[12px] bg-white-900 text-callout text-primary-900 uppercase rounded-[8px]">Log In</button>
                        </Link>
                        <button type="button"
                            className="flex-1 px-[16px] py-[12px] bg-accent-900 text-callout text-white-900 uppercase rounded-[8px]"
                            onClick={() => {
                                router.push(`/auth/register?return=${router.asPath}`)
                            }}>
                            Sign Up
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AFFSidebarHeader
